import React from "react";
import { useDebouncedCallback } from "use-lodash-debounce";
import Preview from "./Preview";
import { ControlledEditor } from "@monaco-editor/react";
import { AppBar, Toolbar, Button, Typography } from "@material-ui/core";
import { Tabs, Tab, Box } from "@material-ui/core";
import "./css/main.css";
import "./css/style.css";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...other}
      children={value === index && children}
    />
  );
}

export default function App() {
  const [code, setCode] = React.useState("");
  const [html, setHtml] = React.useState("");
  const [locals, setLocals] = React.useState({});
  const [context, setContext] = React.useState("");
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [options] = React.useState({
    selectOnLineNumbers: true,
    minimap: {
      enabled: false
    }
  });

  // ---
  const onCodeChange = useDebouncedCallback((e, value) => {
    setCode(value);
  }, 500);

  const onContextChange = useDebouncedCallback((e, value) => {
    setContext(value);
  }, 500);
  // ---

  const onClick = () => {
    let obj = {};
    try {
      obj = JSON.parse(context);
    } catch (error) {}
    setLocals(obj);
    setHtml(code);
  };

  // ---

  const handleTabChange = (event, value) => {
    setSelectedTab(value);
  };

  return (
    <div>
      <AppBar position="fixed">
        <Toolbar variant="dense">
          <Typography variant="h6" color="inherit">
            Handlebars Playground
          </Typography>
          <div className="flex-1"></div>
          <Button variant="contained" color="secondary" onClick={onClick}>
            Compile
          </Button>
        </Toolbar>
      </AppBar>

      <div className="flex flex-wrap px-2 h-screen" style={{ marginTop: 64 }}>
        <div className="w-full h-screen sm:w-1/2 shadow-lg overflow-hidden rounded">
          <AppBar position="static">
            <Tabs value={selectedTab} onChange={handleTabChange}>
              <Tab label="Template" {...a11yProps(0)} />
              <Tab label="Context" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <TabPanel value={selectedTab} index={0} className="h-full">
            <ControlledEditor
              language="handlebars"
              value={code}
              options={options}
              onChange={onCodeChange}
            />
          </TabPanel>
          <TabPanel value={selectedTab} index={1} className="h-full">
            <ControlledEditor
              language="json"
              value={context}
              options={options}
              onChange={onContextChange}
            />
          </TabPanel>
        </div>
        <div className="w-full h-screen sm:w-1/2">
          <div className="shadow-lg h-screen mt-4 sm:mt-0 sm:mx-2 rounded overflow-hidden">
            <Preview code={html} context={locals} />
          </div>
        </div>
      </div>
      <div className="py-4" />
    </div>
  );
}
