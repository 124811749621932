import React from "react";
import Iframe from "./Iframe";
import parseHtml from "html-react-parser";
import hbs from "./handlebars";

function generateHtml(code = "", context = {}) {
  try {
    const template = hbs.compile(code);
    const html = template(context);
    return html;
  } catch (error) {
    console.error(error);
    return "";
  }
}

export default function Preview(props) {
  const html = generateHtml(props.code, props.context);

  const styles = {
    borderWidth: 0,
    width: "100%"
  };

  return (
    <Iframe style={styles}>
      <>{parseHtml(html)}</>
    </Iframe>
  );
}
