import hbs from "handlebars";
import _ from "lodash";

hbs.registerHelper("entityContainsData", function(value) {
  if (!value) {
    return false;
  }
  return !_.isEmpty(value.teen) || !_.isEmpty(value.parents);
});

export default hbs;
